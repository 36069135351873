<template>
    <v-field
        v-model="model"
        :name="label"
        :rules="rules"
        v-slot="{ field, errors, errorMessage, meta}"
        @input="$emit('update:modelValue', $event.target.value)">
        <div :class="cssClass">
            <label v-if="label" :for="'input_'+name" class="form-label">{{ label }}</label>

            <slot :field="field">
                <input v-bind="field" :type="type" class="form-control" :class="{'is-invalid':!meta.valid && meta.touched}" :id="'input_'+name">
            </slot>

            <div v-if="errors[0]" class="invalid-feedback" style="display: block;">
                {{ errors[0] }}
            </div>
        </div>
    </v-field>
</template>

<script>
    export default {

        computed: {
            model: {
                get() {return this.modelValue},
                set(newValue) {
                    // Note: we are using destructuring assignment syntax here.
                    this.$emit('update:modelValue', newValue)
                }
            }
        },

        props: {
            name: {
                type: String,
                default: ''
            },
            type: {
                type: String,
                default: 'text'
            },
            label: {
                type: String,
                default: ''
            },
            cssClass: {
                type: String,
                default: ''
            },
            modelValue: {
            },
            rules: {
                type: [String, Array, Object],
                description: 'Vee validate validation rules',
                default: ''
            },
        },
    };
</script>