import { default as activateT1T560Q6jCMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/auth/activate.vue?macro=true";
import { default as indexPhs4wjAKGNMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/auth/index.vue?macro=true";
import { default as restore6vNblR18PrMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/auth/organisations/restore.vue?macro=true";
import { default as register_45completeXYhNXpaKBkMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/auth/register-complete.vue?macro=true";
import { default as registerPqRZqTO8oxMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/auth/register.vue?macro=true";
import { default as _91token_93FKGNi2SyfeMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/boards/[token].vue?macro=true";
import { default as indexN8tCUJorgPMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/buzzer/index.vue?macro=true";
import { default as _91id_93TDbC2lprgwMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/dashboards/[id].vue?macro=true";
import { default as indexA9tdsc5riAMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/dashboards/index.vue?macro=true";
import { default as _91token_93XqUYVXpmh2Meta } from "/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/dashboards/token/[token].vue?macro=true";
import { default as token4F0qkhfueWMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/dashboards/token.vue?macro=true";
import { default as demojuDIzJoHaiMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/demo.vue?macro=true";
import { default as indexhErvd5BCsJMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/events/index.vue?macro=true";
import { default as impressumATzwYtvSxSMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/impressum.vue?macro=true";
import { default as indexvQA2V76RvjMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/index.vue?macro=true";
import { default as _91id_93d9SuMzNLw4Meta } from "/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/leaderboards/[id].vue?macro=true";
import { default as indexEwE8nLhW6LMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/leaderboards/index.vue?macro=true";
import { default as _91id_93TeIG88esAyMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/organisation/deals/[id].vue?macro=true";
import { default as indexEhQBebrZcvMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/organisation/deals/index.vue?macro=true";
import { default as HSLtoRGBZfA8cvomuWMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/organisation/devices/HSLtoRGB.js?macro=true";
import { default as RGBtoHSLwwVJ2S8tV1Meta } from "/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/organisation/devices/RGBtoHSL.js?macro=true";
import { default as _91id_93QenxAgVqoXMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/organisation/devices/[id].vue?macro=true";
import { default as _91id_93LUB52pizB5Meta } from "/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/organisation/devices/devicegroup/[id].vue?macro=true";
import { default as indexRGH8rqnJmeMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/organisation/devices/index.vue?macro=true";
import { default as _91id_931mABJ8UR31Meta } from "/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/organisation/eventTypes/[id].vue?macro=true";
import { default as indexHCNrdUo7N0Meta } from "/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/organisation/eventTypes/index.vue?macro=true";
import { default as _91id_93gOM8fXqsWrMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/organisation/goals/[id].vue?macro=true";
import { default as indexofJS04Slb6Meta } from "/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/organisation/goals/index.vue?macro=true";
import { default as indexldWWPWXP93Meta } from "/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/organisation/settings/index.vue?macro=true";
import { default as indexltPyW8bJLNMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/organisation/subscriptions/index.vue?macro=true";
import { default as _91id_93fIRPmFkGOyMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/organisation/users/[id].vue?macro=true";
import { default as indexghMWERwK4jMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/organisation/users/index.vue?macro=true";
import { default as _91id_93dy9BSUFjlGMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/sounds/[id].vue?macro=true";
import { default as entriesOYVfHjdzxeMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/sounds/index/entries.vue?macro=true";
import { default as playlistsHY9AzYXW7MMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/sounds/index/playlists.vue?macro=true";
import { default as indexy5uHEINDnPMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/sounds/index.vue?macro=true";
import { default as indexmKX3bwWt63Meta } from "/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/system/devices/index.vue?macro=true";
import { default as _91id_935gs9K8Ah8TMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/system/firmware/[id].vue?macro=true";
import { default as indexmwHv7Xmx0jMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/system/firmware/index.vue?macro=true";
import { default as _91id_93842BmO5rFjMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/system/hardware/[id].vue?macro=true";
import { default as indexXx9f6Sys1iMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/system/hardware/index.vue?macro=true";
import { default as indexzDLoriNMNqMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/system/logs/index.vue?macro=true";
import { default as _91id_93JtPOqokpmXMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/system/notificationTemplates/[id].vue?macro=true";
import { default as indexSiBZdT8SuBMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/system/notificationTemplates/index.vue?macro=true";
import { default as _91id_93khvUawRCQjMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/system/organisations/[id].vue?macro=true";
import { default as indexBIh6nuD2fVMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/system/organisations/index.vue?macro=true";
import { default as _91id_93JoZaPB8mLoMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/system/plans/[id].vue?macro=true";
import { default as indexPVnfHVSXe1Meta } from "/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/system/plans/index.vue?macro=true";
import { default as releaselogNojZezfzHzMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/system/releaselog.vue?macro=true";
import { default as toolsAGKsrSHjc3Meta } from "/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/system/tools.vue?macro=true";
import { default as _91id_93wu2dJ2h03KMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/system/users/[id].vue?macro=true";
import { default as indexv4SXreHWi0Meta } from "/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/system/users/index.vue?macro=true";
export default [
  {
    name: activateT1T560Q6jCMeta?.name ?? "auth-activate",
    path: activateT1T560Q6jCMeta?.path ?? "/auth/activate",
    meta: activateT1T560Q6jCMeta || {},
    alias: activateT1T560Q6jCMeta?.alias || [],
    redirect: activateT1T560Q6jCMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/auth/activate.vue").then(m => m.default || m)
  },
  {
    name: indexPhs4wjAKGNMeta?.name ?? "auth",
    path: indexPhs4wjAKGNMeta?.path ?? "/auth",
    meta: indexPhs4wjAKGNMeta || {},
    alias: indexPhs4wjAKGNMeta?.alias || [],
    redirect: indexPhs4wjAKGNMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: restore6vNblR18PrMeta?.name ?? "auth-organisations-restore",
    path: restore6vNblR18PrMeta?.path ?? "/auth/organisations/restore",
    meta: restore6vNblR18PrMeta || {},
    alias: restore6vNblR18PrMeta?.alias || [],
    redirect: restore6vNblR18PrMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/auth/organisations/restore.vue").then(m => m.default || m)
  },
  {
    name: register_45completeXYhNXpaKBkMeta?.name ?? "auth-register-complete",
    path: register_45completeXYhNXpaKBkMeta?.path ?? "/auth/register-complete",
    meta: register_45completeXYhNXpaKBkMeta || {},
    alias: register_45completeXYhNXpaKBkMeta?.alias || [],
    redirect: register_45completeXYhNXpaKBkMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/auth/register-complete.vue").then(m => m.default || m)
  },
  {
    name: registerPqRZqTO8oxMeta?.name ?? "auth-register",
    path: registerPqRZqTO8oxMeta?.path ?? "/auth/register",
    meta: registerPqRZqTO8oxMeta || {},
    alias: registerPqRZqTO8oxMeta?.alias || [],
    redirect: registerPqRZqTO8oxMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: _91token_93FKGNi2SyfeMeta?.name ?? "boards-token",
    path: _91token_93FKGNi2SyfeMeta?.path ?? "/boards/:token()",
    meta: _91token_93FKGNi2SyfeMeta || {},
    alias: _91token_93FKGNi2SyfeMeta?.alias || [],
    redirect: _91token_93FKGNi2SyfeMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/boards/[token].vue").then(m => m.default || m)
  },
  {
    name: indexN8tCUJorgPMeta?.name ?? "buzzer",
    path: indexN8tCUJorgPMeta?.path ?? "/buzzer",
    meta: indexN8tCUJorgPMeta || {},
    alias: indexN8tCUJorgPMeta?.alias || [],
    redirect: indexN8tCUJorgPMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/buzzer/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93TDbC2lprgwMeta?.name ?? "dashboards-id",
    path: _91id_93TDbC2lprgwMeta?.path ?? "/dashboards/:id()",
    meta: _91id_93TDbC2lprgwMeta || {},
    alias: _91id_93TDbC2lprgwMeta?.alias || [],
    redirect: _91id_93TDbC2lprgwMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/dashboards/[id].vue").then(m => m.default || m)
  },
  {
    name: indexA9tdsc5riAMeta?.name ?? "dashboards",
    path: indexA9tdsc5riAMeta?.path ?? "/dashboards",
    meta: indexA9tdsc5riAMeta || {},
    alias: indexA9tdsc5riAMeta?.alias || [],
    redirect: indexA9tdsc5riAMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/dashboards/index.vue").then(m => m.default || m)
  },
  {
    name: token4F0qkhfueWMeta?.name ?? "dashboards-token",
    path: token4F0qkhfueWMeta?.path ?? "/dashboards/token",
    children: [
  {
    name: _91token_93XqUYVXpmh2Meta?.name ?? "dashboards-token-token",
    path: _91token_93XqUYVXpmh2Meta?.path ?? ":token()",
    meta: _91token_93XqUYVXpmh2Meta || {},
    alias: _91token_93XqUYVXpmh2Meta?.alias || [],
    redirect: _91token_93XqUYVXpmh2Meta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/dashboards/token/[token].vue").then(m => m.default || m)
  }
],
    meta: token4F0qkhfueWMeta || {},
    alias: token4F0qkhfueWMeta?.alias || [],
    redirect: token4F0qkhfueWMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/dashboards/token.vue").then(m => m.default || m)
  },
  {
    name: demojuDIzJoHaiMeta?.name ?? "demo",
    path: demojuDIzJoHaiMeta?.path ?? "/demo",
    meta: demojuDIzJoHaiMeta || {},
    alias: demojuDIzJoHaiMeta?.alias || [],
    redirect: demojuDIzJoHaiMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/demo.vue").then(m => m.default || m)
  },
  {
    name: indexhErvd5BCsJMeta?.name ?? "events",
    path: indexhErvd5BCsJMeta?.path ?? "/events",
    meta: indexhErvd5BCsJMeta || {},
    alias: indexhErvd5BCsJMeta?.alias || [],
    redirect: indexhErvd5BCsJMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: impressumATzwYtvSxSMeta?.name ?? "impressum",
    path: impressumATzwYtvSxSMeta?.path ?? "/impressum",
    meta: impressumATzwYtvSxSMeta || {},
    alias: impressumATzwYtvSxSMeta?.alias || [],
    redirect: impressumATzwYtvSxSMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: indexvQA2V76RvjMeta?.name ?? "index",
    path: indexvQA2V76RvjMeta?.path ?? "/",
    meta: indexvQA2V76RvjMeta || {},
    alias: indexvQA2V76RvjMeta?.alias || [],
    redirect: indexvQA2V76RvjMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93d9SuMzNLw4Meta?.name ?? "leaderboards-id",
    path: _91id_93d9SuMzNLw4Meta?.path ?? "/leaderboards/:id()",
    meta: _91id_93d9SuMzNLw4Meta || {},
    alias: _91id_93d9SuMzNLw4Meta?.alias || [],
    redirect: _91id_93d9SuMzNLw4Meta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/leaderboards/[id].vue").then(m => m.default || m)
  },
  {
    name: indexEwE8nLhW6LMeta?.name ?? "leaderboards",
    path: indexEwE8nLhW6LMeta?.path ?? "/leaderboards",
    meta: indexEwE8nLhW6LMeta || {},
    alias: indexEwE8nLhW6LMeta?.alias || [],
    redirect: indexEwE8nLhW6LMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/leaderboards/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93TeIG88esAyMeta?.name ?? "organisation-deals-id",
    path: _91id_93TeIG88esAyMeta?.path ?? "/organisation/deals/:id()",
    meta: _91id_93TeIG88esAyMeta || {},
    alias: _91id_93TeIG88esAyMeta?.alias || [],
    redirect: _91id_93TeIG88esAyMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/organisation/deals/[id].vue").then(m => m.default || m)
  },
  {
    name: indexEhQBebrZcvMeta?.name ?? "organisation-deals",
    path: indexEhQBebrZcvMeta?.path ?? "/organisation/deals",
    meta: indexEhQBebrZcvMeta || {},
    alias: indexEhQBebrZcvMeta?.alias || [],
    redirect: indexEhQBebrZcvMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/organisation/deals/index.vue").then(m => m.default || m)
  },
  {
    name: HSLtoRGBZfA8cvomuWMeta?.name ?? "organisation-devices-HSLtoRGB",
    path: HSLtoRGBZfA8cvomuWMeta?.path ?? "/organisation/devices/HSLtoRGB",
    meta: HSLtoRGBZfA8cvomuWMeta || {},
    alias: HSLtoRGBZfA8cvomuWMeta?.alias || [],
    redirect: HSLtoRGBZfA8cvomuWMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/organisation/devices/HSLtoRGB.js").then(m => m.default || m)
  },
  {
    name: RGBtoHSLwwVJ2S8tV1Meta?.name ?? "organisation-devices-RGBtoHSL",
    path: RGBtoHSLwwVJ2S8tV1Meta?.path ?? "/organisation/devices/RGBtoHSL",
    meta: RGBtoHSLwwVJ2S8tV1Meta || {},
    alias: RGBtoHSLwwVJ2S8tV1Meta?.alias || [],
    redirect: RGBtoHSLwwVJ2S8tV1Meta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/organisation/devices/RGBtoHSL.js").then(m => m.default || m)
  },
  {
    name: _91id_93QenxAgVqoXMeta?.name ?? "organisation-devices-id",
    path: _91id_93QenxAgVqoXMeta?.path ?? "/organisation/devices/:id()",
    meta: _91id_93QenxAgVqoXMeta || {},
    alias: _91id_93QenxAgVqoXMeta?.alias || [],
    redirect: _91id_93QenxAgVqoXMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/organisation/devices/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93LUB52pizB5Meta?.name ?? "organisation-devices-devicegroup-id",
    path: _91id_93LUB52pizB5Meta?.path ?? "/organisation/devices/devicegroup/:id()",
    meta: _91id_93LUB52pizB5Meta || {},
    alias: _91id_93LUB52pizB5Meta?.alias || [],
    redirect: _91id_93LUB52pizB5Meta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/organisation/devices/devicegroup/[id].vue").then(m => m.default || m)
  },
  {
    name: indexRGH8rqnJmeMeta?.name ?? "organisation-devices",
    path: indexRGH8rqnJmeMeta?.path ?? "/organisation/devices",
    meta: indexRGH8rqnJmeMeta || {},
    alias: indexRGH8rqnJmeMeta?.alias || [],
    redirect: indexRGH8rqnJmeMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/organisation/devices/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_931mABJ8UR31Meta?.name ?? "organisation-eventTypes-id",
    path: _91id_931mABJ8UR31Meta?.path ?? "/organisation/eventTypes/:id()",
    meta: _91id_931mABJ8UR31Meta || {},
    alias: _91id_931mABJ8UR31Meta?.alias || [],
    redirect: _91id_931mABJ8UR31Meta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/organisation/eventTypes/[id].vue").then(m => m.default || m)
  },
  {
    name: indexHCNrdUo7N0Meta?.name ?? "organisation-eventTypes",
    path: indexHCNrdUo7N0Meta?.path ?? "/organisation/eventTypes",
    meta: indexHCNrdUo7N0Meta || {},
    alias: indexHCNrdUo7N0Meta?.alias || [],
    redirect: indexHCNrdUo7N0Meta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/organisation/eventTypes/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93gOM8fXqsWrMeta?.name ?? "organisation-goals-id",
    path: _91id_93gOM8fXqsWrMeta?.path ?? "/organisation/goals/:id()",
    meta: _91id_93gOM8fXqsWrMeta || {},
    alias: _91id_93gOM8fXqsWrMeta?.alias || [],
    redirect: _91id_93gOM8fXqsWrMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/organisation/goals/[id].vue").then(m => m.default || m)
  },
  {
    name: indexofJS04Slb6Meta?.name ?? "organisation-goals",
    path: indexofJS04Slb6Meta?.path ?? "/organisation/goals",
    meta: indexofJS04Slb6Meta || {},
    alias: indexofJS04Slb6Meta?.alias || [],
    redirect: indexofJS04Slb6Meta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/organisation/goals/index.vue").then(m => m.default || m)
  },
  {
    name: indexldWWPWXP93Meta?.name ?? "organisation-settings",
    path: indexldWWPWXP93Meta?.path ?? "/organisation/settings",
    meta: indexldWWPWXP93Meta || {},
    alias: indexldWWPWXP93Meta?.alias || [],
    redirect: indexldWWPWXP93Meta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/organisation/settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexltPyW8bJLNMeta?.name ?? "organisation-subscriptions",
    path: indexltPyW8bJLNMeta?.path ?? "/organisation/subscriptions",
    meta: indexltPyW8bJLNMeta || {},
    alias: indexltPyW8bJLNMeta?.alias || [],
    redirect: indexltPyW8bJLNMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/organisation/subscriptions/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93fIRPmFkGOyMeta?.name ?? "organisation-users-id",
    path: _91id_93fIRPmFkGOyMeta?.path ?? "/organisation/users/:id()",
    meta: _91id_93fIRPmFkGOyMeta || {},
    alias: _91id_93fIRPmFkGOyMeta?.alias || [],
    redirect: _91id_93fIRPmFkGOyMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/organisation/users/[id].vue").then(m => m.default || m)
  },
  {
    name: indexghMWERwK4jMeta?.name ?? "organisation-users",
    path: indexghMWERwK4jMeta?.path ?? "/organisation/users",
    meta: indexghMWERwK4jMeta || {},
    alias: indexghMWERwK4jMeta?.alias || [],
    redirect: indexghMWERwK4jMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/organisation/users/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93dy9BSUFjlGMeta?.name ?? "sounds-id",
    path: _91id_93dy9BSUFjlGMeta?.path ?? "/sounds/:id()",
    meta: _91id_93dy9BSUFjlGMeta || {},
    alias: _91id_93dy9BSUFjlGMeta?.alias || [],
    redirect: _91id_93dy9BSUFjlGMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/sounds/[id].vue").then(m => m.default || m)
  },
  {
    name: indexy5uHEINDnPMeta?.name ?? "sounds",
    path: indexy5uHEINDnPMeta?.path ?? "/sounds",
    children: [
  {
    name: entriesOYVfHjdzxeMeta?.name ?? "sounds-index-entries",
    path: entriesOYVfHjdzxeMeta?.path ?? "entries",
    meta: entriesOYVfHjdzxeMeta || {},
    alias: entriesOYVfHjdzxeMeta?.alias || [],
    redirect: entriesOYVfHjdzxeMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/sounds/index/entries.vue").then(m => m.default || m)
  },
  {
    name: playlistsHY9AzYXW7MMeta?.name ?? "sounds-index-playlists",
    path: playlistsHY9AzYXW7MMeta?.path ?? "playlists",
    meta: playlistsHY9AzYXW7MMeta || {},
    alias: playlistsHY9AzYXW7MMeta?.alias || [],
    redirect: playlistsHY9AzYXW7MMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/sounds/index/playlists.vue").then(m => m.default || m)
  }
],
    meta: indexy5uHEINDnPMeta || {},
    alias: indexy5uHEINDnPMeta?.alias || [],
    redirect: indexy5uHEINDnPMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/sounds/index.vue").then(m => m.default || m)
  },
  {
    name: indexmKX3bwWt63Meta?.name ?? "system-devices",
    path: indexmKX3bwWt63Meta?.path ?? "/system/devices",
    meta: indexmKX3bwWt63Meta || {},
    alias: indexmKX3bwWt63Meta?.alias || [],
    redirect: indexmKX3bwWt63Meta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/system/devices/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_935gs9K8Ah8TMeta?.name ?? "system-firmware-id",
    path: _91id_935gs9K8Ah8TMeta?.path ?? "/system/firmware/:id()",
    meta: _91id_935gs9K8Ah8TMeta || {},
    alias: _91id_935gs9K8Ah8TMeta?.alias || [],
    redirect: _91id_935gs9K8Ah8TMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/system/firmware/[id].vue").then(m => m.default || m)
  },
  {
    name: indexmwHv7Xmx0jMeta?.name ?? "system-firmware",
    path: indexmwHv7Xmx0jMeta?.path ?? "/system/firmware",
    meta: indexmwHv7Xmx0jMeta || {},
    alias: indexmwHv7Xmx0jMeta?.alias || [],
    redirect: indexmwHv7Xmx0jMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/system/firmware/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93842BmO5rFjMeta?.name ?? "system-hardware-id",
    path: _91id_93842BmO5rFjMeta?.path ?? "/system/hardware/:id()",
    meta: _91id_93842BmO5rFjMeta || {},
    alias: _91id_93842BmO5rFjMeta?.alias || [],
    redirect: _91id_93842BmO5rFjMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/system/hardware/[id].vue").then(m => m.default || m)
  },
  {
    name: indexXx9f6Sys1iMeta?.name ?? "system-hardware",
    path: indexXx9f6Sys1iMeta?.path ?? "/system/hardware",
    meta: indexXx9f6Sys1iMeta || {},
    alias: indexXx9f6Sys1iMeta?.alias || [],
    redirect: indexXx9f6Sys1iMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/system/hardware/index.vue").then(m => m.default || m)
  },
  {
    name: indexzDLoriNMNqMeta?.name ?? "system-logs",
    path: indexzDLoriNMNqMeta?.path ?? "/system/logs",
    meta: indexzDLoriNMNqMeta || {},
    alias: indexzDLoriNMNqMeta?.alias || [],
    redirect: indexzDLoriNMNqMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/system/logs/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93JtPOqokpmXMeta?.name ?? "system-notificationTemplates-id",
    path: _91id_93JtPOqokpmXMeta?.path ?? "/system/notificationTemplates/:id()",
    meta: _91id_93JtPOqokpmXMeta || {},
    alias: _91id_93JtPOqokpmXMeta?.alias || [],
    redirect: _91id_93JtPOqokpmXMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/system/notificationTemplates/[id].vue").then(m => m.default || m)
  },
  {
    name: indexSiBZdT8SuBMeta?.name ?? "system-notificationTemplates",
    path: indexSiBZdT8SuBMeta?.path ?? "/system/notificationTemplates",
    meta: indexSiBZdT8SuBMeta || {},
    alias: indexSiBZdT8SuBMeta?.alias || [],
    redirect: indexSiBZdT8SuBMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/system/notificationTemplates/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93khvUawRCQjMeta?.name ?? "system-organisations-id",
    path: _91id_93khvUawRCQjMeta?.path ?? "/system/organisations/:id()",
    meta: _91id_93khvUawRCQjMeta || {},
    alias: _91id_93khvUawRCQjMeta?.alias || [],
    redirect: _91id_93khvUawRCQjMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/system/organisations/[id].vue").then(m => m.default || m)
  },
  {
    name: indexBIh6nuD2fVMeta?.name ?? "system-organisations",
    path: indexBIh6nuD2fVMeta?.path ?? "/system/organisations",
    meta: indexBIh6nuD2fVMeta || {},
    alias: indexBIh6nuD2fVMeta?.alias || [],
    redirect: indexBIh6nuD2fVMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/system/organisations/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93JoZaPB8mLoMeta?.name ?? "system-plans-id",
    path: _91id_93JoZaPB8mLoMeta?.path ?? "/system/plans/:id()",
    meta: _91id_93JoZaPB8mLoMeta || {},
    alias: _91id_93JoZaPB8mLoMeta?.alias || [],
    redirect: _91id_93JoZaPB8mLoMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/system/plans/[id].vue").then(m => m.default || m)
  },
  {
    name: indexPVnfHVSXe1Meta?.name ?? "system-plans",
    path: indexPVnfHVSXe1Meta?.path ?? "/system/plans",
    meta: indexPVnfHVSXe1Meta || {},
    alias: indexPVnfHVSXe1Meta?.alias || [],
    redirect: indexPVnfHVSXe1Meta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/system/plans/index.vue").then(m => m.default || m)
  },
  {
    name: releaselogNojZezfzHzMeta?.name ?? "system-releaselog",
    path: releaselogNojZezfzHzMeta?.path ?? "/system/releaselog",
    meta: releaselogNojZezfzHzMeta || {},
    alias: releaselogNojZezfzHzMeta?.alias || [],
    redirect: releaselogNojZezfzHzMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/system/releaselog.vue").then(m => m.default || m)
  },
  {
    name: toolsAGKsrSHjc3Meta?.name ?? "system-tools",
    path: toolsAGKsrSHjc3Meta?.path ?? "/system/tools",
    meta: toolsAGKsrSHjc3Meta || {},
    alias: toolsAGKsrSHjc3Meta?.alias || [],
    redirect: toolsAGKsrSHjc3Meta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/system/tools.vue").then(m => m.default || m)
  },
  {
    name: _91id_93wu2dJ2h03KMeta?.name ?? "system-users-id",
    path: _91id_93wu2dJ2h03KMeta?.path ?? "/system/users/:id()",
    meta: _91id_93wu2dJ2h03KMeta || {},
    alias: _91id_93wu2dJ2h03KMeta?.alias || [],
    redirect: _91id_93wu2dJ2h03KMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/system/users/[id].vue").then(m => m.default || m)
  },
  {
    name: indexv4SXreHWi0Meta?.name ?? "system-users",
    path: indexv4SXreHWi0Meta?.path ?? "/system/users",
    meta: indexv4SXreHWi0Meta || {},
    alias: indexv4SXreHWi0Meta?.alias || [],
    redirect: indexv4SXreHWi0Meta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241029081412/client/pages/system/users/index.vue").then(m => m.default || m)
  }
]