import {useAbility} from "@casl/vue";

export default defineNuxtRouteMiddleware((to) => {
    const {can} = useAbility()

    if (to.path.includes('system') && !can("admin", "system")) {
        return navigateTo('/')
    }

    if (to.path.includes('organisation') && !can("manager", "system")) {
        return navigateTo('/')
    }
})