/*
*  core configuration for farbcode projects
*
*/

// global components
import BaseInput from '~/components/farbcode-core/Inputs/BaseInput.vue';
import BaseInputFloating from '~/components/farbcode-core/Inputs/BaseInputFloating.vue';
import BaseCheckbox from '~/components/farbcode-core/Inputs/BaseCheckbox.vue';

import vueSelect from 'vue-select'

import {gsap} from 'gsap/dist/gsap'
import {ScrollTrigger} from "gsap/dist/ScrollTrigger"
import {CustomEase} from "gsap/dist/CustomEase"
import {Physics2DPlugin} from "gsap/dist/Physics2DPlugin"

//import pinia and pinia persist
//import { createPinia } from 'pinia'
//import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

//setup pinia
//const pinia = createPinia()
//pinia.use(piniaPluginPersistedstate)


export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.component('BaseInput', BaseInput)
    nuxtApp.vueApp.component('BaseInputFloating', BaseInputFloating)
    nuxtApp.vueApp.component('BaseCheckbox', BaseCheckbox)
    nuxtApp.vueApp.component('VSelect', vueSelect)


    gsap.registerPlugin(ScrollTrigger, CustomEase, Physics2DPlugin);

    nuxtApp.provide('gsap', gsap)
    nuxtApp.provide('ScrollTrigger', ScrollTrigger)
    nuxtApp.provide('Physics2DPlugin', Physics2DPlugin)
    nuxtApp.provide('CustomEase', CustomEase)
})
