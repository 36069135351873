<template>
    <v-field
        v-model="model"
        :name="name"
        :rules="rules"
        type="checkbox"
        :value="true"
        :unchecked-value="false"
        v-slot="{ field, errors, errorMessage, meta }"
    >
        <div class="form-check" :class="cssClass">
            <input v-bind="field" :disabled="disabled" :value="true" type="checkbox" class="form-check-input" :class="{'is-invalid':!meta.valid && meta.touched}" :id="name">

            <label :for="name" class="form-check-label">
                <slot></slot>
                {{label}}
            </label>

            <div class="invalid-feedback">{{ errorMessage }}</div>
        </div>
    </v-field>
</template>

<script>
    export default {
        emits: ['input', 'update:modelValue'],
        props: {
            name: {
                type: String,
                default: ''
            },
            label: {
                type: String,
                default: ''
            },
            cssClass: {
                type: String,
                default: ''
            },
            modelValue: {
                type: [String, Number, Boolean],
                default: false
            },
            rules: {
                type: [String, Array, Object],
                description: 'Vee validate validation rules',
                default: ''
            },
            disabled: {
                type: Boolean,
                default: false
            },
        },

        computed: {
            model: {
                get() {
                    return this.modelValue;
                },
                set(check) {
                    if (!this.touched) {
                        this.touched = true;
                    }
                    this.$emit('update:modelValue', check);
                }
            },
        },
    };
</script>