import {library, config} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon,FontAwesomeLayers, FontAwesomeLayersText} from '@fortawesome/vue-fontawesome'
// import {fas} from '@fortawesome/pro-solid-svg-icons'
// import {far} from '@fortawesome/pro-regular-svg-icons'
// import {fal} from '@fortawesome/pro-light-svg-icons'

import {fas} from '@fortawesome/free-solid-svg-icons'
import {far} from '@fortawesome/free-regular-svg-icons'
import {fab} from '@fortawesome/free-brands-svg-icons'

// This is important, we are going to let Nuxt.js worry about the CSS
config.autoAddCss = false

library.add(fas, fab, far)

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.component('font-awesome-icon', FontAwesomeIcon)
    nuxtApp.vueApp.component('font-awesome-layers', FontAwesomeLayers)
    nuxtApp.vueApp.component('font-awesome-layers-text', FontAwesomeLayersText)
})