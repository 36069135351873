import type { ModuleOptions } from '#cookie-control/types'

export default {
  "barPosition": "bottom-full",
  "closeModalOnClickOutside": false,
  "colors": {
    "barBackground": "#000",
    "barButtonBackground": "#fff",
    "barButtonColor": "#000",
    "barButtonHoverBackground": "#333",
    "barButtonHoverColor": "#fff",
    "barTextColor": "#fff",
    "checkboxActiveBackground": "#000",
    "checkboxActiveCircleBackground": "#fff",
    "checkboxDisabledBackground": "#ddd",
    "checkboxDisabledCircleBackground": "#fff",
    "checkboxInactiveBackground": "#000",
    "checkboxInactiveCircleBackground": "#fff",
    "controlButtonBackground": "#fff",
    "controlButtonHoverBackground": "#000",
    "controlButtonIconColor": "#000",
    "controlButtonIconHoverColor": "#fff",
    "focusRingColor": "#808080",
    "modalBackground": "#fff",
    "modalButtonBackground": "#000",
    "modalButtonColor": "#fff",
    "modalButtonHoverBackground": "#333",
    "modalButtonHoverColor": "#fff",
    "modalOverlay": "#000",
    "modalOverlayOpacity": 0.8,
    "modalTextColor": "#000",
    "modalUnsavedColor": "#fff"
  },
  "cookies": {
    "necessary": [],
    "optional": [
      {
        "description": {
          "en": "This cookie enables the Google Tag Manager",
          "de": "This cookie enables the Google Tag Manager"
        },
        "name": {
          "en": "Google Tag Manager",
          "de": "Google Tag Manager"
        },
        "targetCookieIds": [
          "xmpl_a",
          "xmpl_b"
        ]
      },
      {
        "description": {
          "en": "This cookie enables the Meta-Pixel",
          "de": "This cookie enables the Meta-Pixel"
        },
        "name": {
          "en": "Meta-Pixel",
          "de": "Meta-Pixel"
        },
        "targetCookieIds": [
          "_fbp"
        ]
      }
    ]
  },
  "cookieExpiryOffsetMs": 31536000000,
  "cookieNameIsConsentGiven": "ncc_c",
  "cookieNameCookiesEnabledIds": "ncc_e",
  "cookieOptions": {
    "path": "/"
  },
  "isAcceptNecessaryButtonEnabled": true,
  "isControlButtonEnabled": false,
  "isCookieIdVisible": false,
  "isCssEnabled": true,
  "isCssPonyfillEnabled": false,
  "isDashInDescriptionEnabled": true,
  "isIframeBlocked": false,
  "isModalForced": false,
  "locales": [
    "en",
    "de",
    "en"
  ],
  "localeTexts": {
    "en": {
      "accept": "Accept",
      "acceptAll": "Accept all",
      "bannerDescription": "We use our own cookies and third-party cookies so that we can display this website correctly and better understand how this website is used, with a view to improving the services we offer. A decision on cookie usage permissions can be changed anytime using the cookie button that will appear after a selection has been made on this banner.",
      "bannerTitle": "Cookies",
      "close": "Close",
      "cookiesFunctional": "Functional cookies",
      "cookiesNecessary": "Necessary cookies",
      "cookiesOptional": "Optional cookies",
      "decline": "Decline",
      "declineAll": "Decline all",
      "here": "here",
      "iframeBlocked": "To see this, please enable functional cookies",
      "manageCookies": "Learn more and customize",
      "save": "Save",
      "settingsUnsaved": "You have unsaved settings"
    },
    "de": {
      "accept": "Akzeptieren",
      "acceptAll": "Alle akzeptieren",
      "bannerDescription": "Wir verwenden eigene Cookies und Cookies von Drittanbietern, damit wir diese Webseite korrekt darstellen können und besser verstehen, wie diese Webseite genutzt wird, um unser Angebot zu verbessern. Eine Entscheidung über die Erlaubnis zur Verwendung von Cookies kann jederzeit über den Cookie-Button geändert werden, der erscheint, nachdem eine Auswahl auf diesem Banner getroffen wurde.",
      "bannerTitle": "Cookies",
      "close": "Schließen",
      "cookiesFunctional": "Funktionale Cookies",
      "cookiesNecessary": "Notwendige Cookies",
      "cookiesOptional": "Optionale Cookies",
      "decline": "Ablehnen",
      "declineAll": "Alle ablehnen",
      "here": "hier",
      "iframeBlocked": "Um den Inhalt zu sehen, aktivieren Sie bitte funktionale Cookies",
      "manageCookies": "Mehr erfahren und anpassen",
      "save": "Speichern",
      "settingsUnsaved": "Es gibt nicht gespeicherte Einstellungen"
    }
  }
} as ModuleOptions