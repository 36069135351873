import {Field, Form,ErrorMessage, configure, defineRule} from 'vee-validate';
import AllRules from '@vee-validate/rules';
import { localize, setLocale } from '@vee-validate/i18n';
import en from '@vee-validate/i18n/dist/locale/en.json';
import de from '@vee-validate/i18n/dist/locale/de.json';
import zxcvbn from "zxcvbn";

export default defineNuxtPlugin((nuxtApp) => {

    Object.keys(AllRules).forEach(rule => {
        defineRule(rule, AllRules[rule]);
    });

    configure({
        generateMessage: localize({
            en: {
                messages: {
                    ...en.messages,
                    verify_password: `The password is too weak. Please choose a stronger password`,
                    verify_url: 'The URL is not valid'
                }
            },
            de: {
                messages: {
                    ...de.messages,
                    verify_password: `Das Passwort ist zu schwach. Bitte wähle ein stärkeres Passwort`,
                    verify_url: 'Die URL ist ungültig'
                }
            },
        }),
    });


    setLocale(nuxtApp.$i18n.locale.value);

    nuxtApp.vueApp.component("VField", Field)
    nuxtApp.vueApp.component("VForm", Form)
    nuxtApp.vueApp.component("ErrorMessage", ErrorMessage)

    defineRule('verify_password', value => {
        let score = value ? zxcvbn(value).score : null
        if (score <= 2) return false

        return true
    });

    defineRule('verify_url', value => {
        var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        if (!pattern.test(value)) return false

        return true
    });

    configure({
        classes: {
            valid: 'is-valid',
            invalid: 'is-invalid',
            dirty: ['is-dirty', 'is-dirty'], // multiple classes per flag!
        }
    });
})