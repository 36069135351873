<template>
    <div id="color-root">
        <nuxt-layout>
            <CookieControl :locale="locale"/>
            <nuxt-page/>
        </nuxt-layout>
    </div>
</template>

<script setup>
import 'bootstrap/js/dist/collapse'
import 'bootstrap/js/dist/offcanvas'
import 'bootstrap/js/dist/modal'
import 'bootstrap/js/dist/dropdown'
import 'bootstrap/js/dist/tab'
import {useAuthStore} from '@/store/auth'

const {locale} = useI18n()
const authStore = useAuthStore()
const {cookiesEnabledIds} = useCookieControl()
const route = useRoute()
const { t } = useI18n()

const theme = ref(
    {
        primaryColor: '#F5FE46',
        primaryHue: '7deg'
    }
)
const themeDefault = ref(
    {
        primaryColor: '#F5FE46',
        primaryHue: '7deg'
    }
)

const title = computed(() => {
    if(route.name) return t(`pageTitle.${route.name}`)
})

useHead(() => ({
    title: (title.value) ? `Deal Buzzer - ${title.value}` : 'Deal Buzzer'
}))

//register service worker
if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/sw.js')
        .then(function (registration) {
            console.log('Registration successful, scope is:', registration.scope);
        })
        .catch(function (error) {
            console.log('Service worker registration failed, error:', error);
        });
}

onMounted(() => {
    if (cookiesEnabledIds.value?.includes('google-tag-manager')) enableGtm();

    if (cookiesEnabledIds.value?.includes('meta-pixel')) enableMeta();

})

watch(
    () => cookiesEnabledIds.value, (current, previous) => {
        if (!previous?.includes('google-tag-manager') && current?.includes('google-tag-manager')) enableGtm();

        if (!previous?.includes('meta-pixel') && current?.includes('meta-pixel')) enableMeta();
    },
)

function enableGtm() {
    useGtm().enable(true);
}

function enableMeta() {
    useNuxtApp().$fb.enable();
}

authStore.$onAction(({name, after}) => {
    after(() => {
        if (name === 'setBranding') {
            if (localStorage.getItem('organisation_branding') === '1') {
                if (localStorage.getItem('organisation_branding_data') !== "" && localStorage.getItem('organisation_branding_data') !== "null") {
                    theme.value = JSON.parse(localStorage.getItem('organisation_branding_data'))
                }
            } else {
                theme.value = themeDefault.value
            }
        }
    })
})
</script>

<style>
#color-root {
    --primary: v-bind('theme.primaryColor');
    --hue-rotate-primary: v-bind('theme.primaryHue');
}
</style>